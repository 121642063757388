import client from '../utils/client';
import { gql } from '@apollo/client';

type ErrorType =
	| 'EMAIL_NOT_EXISTENT'
	| 'EMAIL_ALREADY_CONFIRMED'
	| 'NETWORK_ERROR'
	| 'SEND_CONFIRMATION_EMAIL_FAILED';

const resendConfirmationMail = async (
	email: string
): Promise<{ success: true; error: undefined } | { success: false; error: ErrorType }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation ResendConfirmationMail($email: String!) {
					resendConfirmationMail(email: $email) {
						success
					}
				}
			`,
			variables: {
				email,
			},
		});
		if (errors) {
			return { success: false, error: errors[0]?.extensions?.code as ErrorType };
		}
		return { success: true, error: undefined };
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default resendConfirmationMail;
