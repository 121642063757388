import React from 'react';
import styled, { useTheme } from 'styled-components';
import { TextField, Grid, Box } from '@mui/material';
import { useSnackbar } from '../utils/snackBar';

import useTextFieldController from '../utils/useTextFieldController';
import { fetchUser, setToken } from '../utils/user';
import Button from './Button';
import Dialog from './Dialog';

import confirmEmail from '../mutations/confirmEmail';
import resendConfirmationMail from '../mutations/resendConfirmationMail';

const gridItemProps = {
	xs: 12,
	item: true,
};

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const EmailVerificator = ({
	emailAddress,
	onSuccess,
	onError,
	defaultConfirmationCode,
}: {
	emailAddress: string;
	onSuccess: () => void;
	onError: () => void;
	defaultConfirmationCode?: string;
}): JSX.Element => {
	const theme = useTheme();
	const snackBar = useSnackbar();
	const {
		newStateValue: confirmationCode,
		textInputProps,
		validate: validateCode,
	} = useTextFieldController({
		defaultValue: defaultConfirmationCode || '',
		inputLabel: 'Code',
		stateKey: 'code',
		validationFunction: (value) =>
			!value ? 'Bitte ausfüllen' : value.length !== 6 ? 'Der Code muss 6 Zeichen haben' : undefined,
		stateValueFallback: '',
		trim: true,
		limit: 6,
	});

	const [loading, setLoading] = React.useState<boolean>(false);

	const handleVerify = React.useCallback(async () => {
		const invalid = validateCode!();
		if (!invalid) {
			setLoading(true);

			const { success, error, jwt } = await confirmEmail(emailAddress, confirmationCode!);

			if (success) {
				setToken(jwt!);
				snackBar({ success: 'Email verifiziert!' });
				setLoading(false);
				await fetchUser();
				onSuccess();
			} else {
				setLoading(false);
				if (error === 'CONFIRMATION_CODE_EXPIRED') {
					Dialog.render({
						title: 'Bestätigungscode abgelaufen',
						description:
							'Der Bestätigungscode ist nur 1 Stunde gültig und nun leider abgelaufen. Bitte registriere dich erneut.',
						onClose: onError,
					});
				} else {
					snackBar({ error });
				}
			}
		}
	}, [validateCode, emailAddress, confirmationCode, snackBar, onSuccess, onError]);

	const handleResendConfirmationMail = React.useCallback(async () => {
		setLoading(true);
		const { success, error } = await resendConfirmationMail(emailAddress);
		if (success) {
			snackBar({ success: 'Bestätigungsemail gesendet!' });
		} else {
			snackBar({ error });
		}
		setLoading(false);
	}, [emailAddress, snackBar]);

	const inputProps = React.useMemo(
		() => ({
			style: {
				backgroundColor: theme.palette.common.white,
			},
		}),
		[theme.palette.common.white]
	);

	const autoVerifyRef = React.useRef<boolean>(false);
	React.useEffect(() => {
		if (defaultConfirmationCode && !autoVerifyRef.current) {
			handleVerify();
			autoVerifyRef.current = true;
		}
	}, [defaultConfirmationCode, handleVerify]);

	return (
		<>
			<Grid {...gridItemProps}>
				<TextField fullWidth variant="filled" {...textInputProps} InputProps={inputProps} />
			</Grid>
			<Grid {...gridItemProps}>
				<Row>
					<Button m="0 1rem 0 0" variant="mainButton" onClick={handleVerify} loading={loading}>
						Verifizieren
					</Button>
					<Button variant="secondButton" onClick={handleResendConfirmationMail} loading={loading}>
						Erneut senden
					</Button>
				</Row>
			</Grid>
		</>
	);
};

export default EmailVerificator;
