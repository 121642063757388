import React from 'react';
import { useQuery, gql } from '@apollo/client';

export const usePublicStatistics = ():
	| { label: 'Nutzer' | 'Kurse'; value: number }[]
	| undefined => {
	const { data } = useQuery(
		gql`
			query {
				publicStatistics {
					label
					value
				}
			}
		`
	);

	return React.useMemo(() => data?.publicStatistics, [data?.publicStatistics]);
};

// TODO: move this elsewhere
type MaintenanceInfo = { isMaintained: boolean; title?: string; description?: string };

export const useMaintenanceInfo = (): MaintenanceInfo | undefined => {
	const { data } = useQuery(
		gql`
			query {
				maintenanceInfo {
					isMaintained
					title
					description
				}
			}
		`
	);

	return React.useMemo(() => data?.maintenanceInfo, [data?.maintenanceInfo]);
};
