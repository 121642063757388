import React from 'react';
import ContentWrapper from '../components/ContentWrapper';
import Txt from '../components/Txt';

const NotFoundView = () => {
	return (
		<ContentWrapper center minHeight="10rem">
			<Txt>Not found</Txt>
		</ContentWrapper>
	);
};

export default NotFoundView;
