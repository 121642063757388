import React from 'react';
import { Box, CardActionArea } from '@mui/material';
import { useNavigate } from '../utils/hooks';
import styled from 'styled-components';

import { type Category } from '../utils/category';
import ImageBox from './ImageBox';
import Txt from './Txt';

export const WIDTH: number = 15;
export const HEIGHT: number = 17;
export const MARGINS: [number, number, number, number] = [0, 3, 0, 0];

const Wrapper = styled(Box)`
	min-width: ${WIDTH}rem;
	width: ${WIDTH}rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	border: 1px solid ${(p) => p.theme.customColors.lightGrey};
	overflow: hidden;
	height: ${HEIGHT}rem;
	margin: ${MARGINS.map((m) => `${m}rem`).join(' ')};
`;

const StyledRow = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 0 1rem;
	background-color: ${(p) => p.theme.customColors.orange};
`;

const DemoContentTile = ({
	id,
	imageUrl,
	categories,
}: {
	id: string;
	imageUrl?: string;
	categories: Category[];
}) => {
	const navigate = useNavigate();
	const handleNavigateToContent = React.useCallback(() => {
		navigate(`/content/${id}`);
	}, [id, navigate]);

	const audienceCategories = React.useMemo(() => {
		return categories.filter(({ groupName }) => groupName === 'AUDIENCE');
	}, [categories])
		.map(({ title }) => title)
		.join(', ');

	return (
		<Wrapper>
			<CardActionArea onClick={handleNavigateToContent}>
				<StyledRow>
					<Txt variant="caption" numberOfLines={1}>
						{audienceCategories}
					</Txt>
				</StyledRow>

				<ImageBox width="100%" height={`${HEIGHT}rem`} src={imageUrl}></ImageBox>
			</CardActionArea>
		</Wrapper>
	);
};

export default React.memo(DemoContentTile);
