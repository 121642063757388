import React, { Fragment } from 'react';
import styled, { useTheme } from 'styled-components';
import { Box, Grid } from '@mui/material';
import FilterNoneIcon from '@mui/icons-material/FilterNone';

import { InfoBoxContent, ContentDetails } from '../utils/content';
import Txt from './Txt';
import ImageBox from './ImageBox';
import Button from './Button';
import GridContainer from './GridContainer';
import Badge from './Badge';
import LearningGoals from './LearningGoals';
import { useIsMobile } from '../utils/hooks';
import FloatingActionButton2 from './FloatingActionButton2';
import Expandable from './Expandable';

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
`;

const Column = styled(Box)`
	display: flex;
	flex-direction: column;
`;

const IMAGE_WIDTH = 18;
const IMAGE_WIDTH_MOBILE = 30;

const horizontalSpacing = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };
const verticalSpacing = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };

const GreyGrid = styled(Grid)`
	background-color: ${(p) => p.theme.customColors.decentBlue};
`;

const gridItemLeftProps = { item: true, xs: 12, sm: 12, md: 8 };
const gridItemRightProps = { item: true, xs: 12, sm: 12, md: 4 };

const TitleSection = ({
	title,
	shortDescription,
	author,
	onClick,
	buttonText,
	renderContent,
}: {
	title?: string;
	shortDescription?: string;
	author?: string;
	buttonText?: string;
	onClick?: () => void;
	renderContent?: () => JSX.Element;
}) => {
	const isMobile = useIsMobile();

	return (
		<Column p="2rem" flex="2">
			<Txt fontWeight="bold" align="left" variant="h4">
				{title}
			</Txt>
			{shortDescription ? (
				<Txt
					lineBreaks
					m="1rem 0 0"
					numberOfLines={isMobile ? undefined : 2}
					variant="body1"
					display="block"
				>
					{shortDescription}
				</Txt>
			) : null}
			{author && !isMobile ? (
				<Txt lineBreaks m="2rem 0 0" numberOfLines={2} variant="body1" display="block">
					Erstellt von: {author}
				</Txt>
			) : null}
			<Box m="2rem 0 0 0" display="flex" flexDirection="row" alignItems="center">
				{onClick && !isMobile ? (
					<Button m="0 2rem 0 0" variant="mainButton" onClick={onClick}>
						{buttonText}
					</Button>
				) : null}
				{renderContent ? renderContent() : null}
			</Box>
		</Column>
	);
};

const ImageSection = ({
	imageUrl,
	accessibilities,
}: {
	imageUrl?: string;
	accessibilities?: ContentDetails['accessibilities'];
}) => {
	const isMobile = useIsMobile();
	return (
		<Row width="100%" justifyContent="center" height={!imageUrl ? '15rem' : undefined}>
			{!imageUrl ? null : (
				<ImageBox
					maxWidth={isMobile ? `${IMAGE_WIDTH_MOBILE}rem` : `${IMAGE_WIDTH}rem`}
					width="100%"
					src={imageUrl}
					position="relative"
				>
					<Box position="absolute" display="flex" right="0" top="0" p="0.5rem">
						<Badge width="3.5rem" accessibilities={accessibilities} />
					</Box>
				</ImageBox>
			)}
		</Row>
	);
};

const DescriptionSection = ({
	descriptionTitle,
	description,
	learningGoals,
	cooperations,
	p,
	why,
	who,
	what,
	techRequirements,
	requirements,
	structure,
}: {
	descriptionTitle?: string;
	description?: string;
	learningGoals?: ContentDetails['learningGoals'];
	cooperations?: { label: string; link: string }[];
	p?: string;
	why?: string;
	who?: string[];
	what?: string[];
	techRequirements?: string[];
	requirements?: string[];
	structure?: { chapter: string; lessons: string[] }[];
}) => {
	const theme = useTheme();
	const [hidden, setHidden] = React.useState(true);

	const handleToggleHidden = React.useCallback(() => {
		setHidden((before) => !before);
	}, []);

	const details = React.useMemo(
		() =>
			[
				{ title: 'Warum du diesen Kurs absolvieren solltest', text: why },
				{ title: 'Für wen eignet sich dieser Kurs?', items: who },
				{ title: 'Was dich erwartet', items: what },
				{ title: 'Technische Anforderungen', items: techRequirements },
				{ title: 'Voraussetzungen', items: requirements },
			].filter(({ items, text }) => Boolean(items) || Boolean(text)) as {
				title: string;
				items?: string[];
				text?: string;
			}[],
		[requirements, techRequirements, what, who, why]
	);

	const canShowMore = details.length > 1;

	return (
		<Column p={p} flex="2">
			<Txt fontWeight="bold" align="left" variant="h6">
				{descriptionTitle}
			</Txt>
			<Txt lineBreaks m="1rem 0 0" variant="body1" display="block">
				{description}
			</Txt>
			{learningGoals ? (
				<>
					<Txt m="2rem 0 1rem 0" fontWeight="bold" align="left" variant="h6">
						Lernziele
					</Txt>
					<LearningGoals items={learningGoals} />
				</>
			) : null}

			{cooperations ? (
				<>
					<Txt fontWeight="bold" align="left" variant="h6" m="2rem 0 0 0 ">
						Ein Angebot von
					</Txt>
					<Row m="1rem 0 0" width="100%">
						{cooperations.map(({ label, link }, index, array) => (
							<Fragment key={`${label}${link}`}>
								<Txt
									key={link}
									lineBreaks
									m="0 1rem 0 0"
									variant="body1"
									navigateTo={link}
									navigateToExternal
									color={theme.customColors.lightBlue}
								>
									{label}
								</Txt>
								{index < array.length - 1 ? <Txt m="0 1rem 0 0">|</Txt> : null}
							</Fragment>
						))}
					</Row>
				</>
			) : null}

			{structure ? (
				<>
					<Txt fontWeight="bold" align="left" variant="h6" m="2rem 0 0 0 ">
						Inhalt
					</Txt>
					<Column m="1rem 0 0" width="100%">
						{structure.map(({ chapter, lessons }) => (
							<Expandable
								label={chapter}
								rightLabel={lessons.length > 1 ? `${lessons.length} Lektionen` : '1 Lektion'}
								key={chapter}
								hasTitleLineBreak
								hasBoldTitle
								titleBackgroundColor={theme.customColors.decentBlue}
								m="0 0 1rem 0"
							>
								<Box p="1rem">
									{lessons?.map((lesson) => (
										<Box
											key={lesson}
											display="flex"
											flexDirection="row"
											alignItems="flex-start"
											m="0 0 0.5rem 0"
										>
											<FilterNoneIcon
												sx={{ marginTop: '0.5rem', width: '0.75rem', height: '0.75rem' }}
											/>
											<Txt m="0 0 0 0.5rem" key={lesson}>
												{lesson}
											</Txt>
										</Box>
									))}
								</Box>
							</Expandable>
						))}
					</Column>
				</>
			) : null}

			{details.length ? (
				<Txt fontWeight="bold" align="left" variant="h6" m="2rem 0 1.5rem 0 ">
					Details
				</Txt>
			) : null}
			{details.map(({ title, items, text }, index) => (
				<Box m="0 0 1.5rem 0 " key={title} display={index >= 1 && hidden ? 'none' : undefined}>
					<Txt align="left" m="0rem 0 1rem 0 " fontWeight="bold">
						{title}
					</Txt>
					{text ? <Txt align="left">{text}</Txt> : null}
					{items?.map((item) => (
						<Box key={item} display="flex" flexDirection="row">
							<Txt m="0 0.5rem 0 0">•</Txt>
							<Txt>{item}</Txt>
						</Box>
					))}
				</Box>
			))}
			{canShowMore ? (
				hidden ? (
					<Button variant="blueText" onClick={handleToggleHidden}>
						Mehr anzeigen
					</Button>
				) : (
					<Button variant="blueText" onClick={handleToggleHidden}>
						Weniger anzeigen
					</Button>
				)
			) : null}
		</Column>
	);
};

const InfoBoxSection = ({
	infoBoxTitle,
	infoBoxContent,
}: {
	infoBoxTitle?: string;
	infoBoxContent?: InfoBoxContent;
}) => {
	const isMobile = useIsMobile();
	const theme = useTheme();
	const infoBoxStyle = React.useMemo(
		() => ({
			borderBottom: `1px solid ${theme.customColors.lightGrey}`,
			borderRight: isMobile ? undefined : `1px solid ${theme.customColors.lightGrey}`,
			borderLeft: isMobile ? undefined : `1px solid ${theme.customColors.lightGrey}`,
			maxWidth: isMobile ? undefined : `${IMAGE_WIDTH}rem`,
		}),
		[isMobile, theme.customColors.lightGrey]
	);

	return (
		<Row justifyContent="center" flex="1" width="100%">
			{infoBoxContent ? (
				<Box p="1rem" width="100%" {...infoBoxStyle}>
					<Txt m="0 0 1rem 0" fontWeight="bold" lineBreaks numberOfLines={2} variant="body1">
						{infoBoxTitle}
					</Txt>
					{infoBoxContent.map(({ label, value, values, hide }) =>
						hide ? null : (
							<Row m="0 0 0.5rem 0" width="100%" alignItems="center" key={label}>
								<Txt width="50%" variant="caption" fontWeight="bold" numberOfLines={1}>
									{label}:
								</Txt>
								{value ? (
									<Txt width="50%" variant="caption">
										{value}
									</Txt>
								) : values ? (
									values.map((item) => (
										<Txt key={item.label} flex="1" onClick={item.onClick} variant="caption">
											{item.label}
										</Txt>
									))
								) : null}
							</Row>
						)
					)}
				</Box>
			) : null}
		</Row>
	);
};

const ContentHeader = ({
	title,
	shortDescription,
	author,
	buttonText,
	onClick,
	imageUrl,
	descriptionTitle,
	description,
	infoBoxTitle,
	infoBoxContent,
	hideUpperSection,
	renderContent,
	cooperations,
	accessibilities,
	learningGoals,
	why,
	who,
	what,
	techRequirements,
	requirements,
	structure,
}: {
	title?: string;
	shortDescription?: string;
	author?: string;
	buttonText?: string;
	onClick?: () => void;
	imageUrl?: string;
	descriptionTitle?: string;
	description?: string;
	infoBoxTitle?: string;
	infoBoxContent?: InfoBoxContent;
	hideUpperSection?: boolean;
	renderContent?: () => JSX.Element;
	cooperations?: { label: string; link: string }[];
	accessibilities?: ContentDetails['accessibilities'];
	learningGoals?: ContentDetails['learningGoals'];
	why?: string;
	who?: string[];
	what?: string[];
	techRequirements?: string[];
	requirements?: string[];
	structure?: { chapter: string; lessons: string[] }[];
}): JSX.Element | null => {
	const theme = useTheme();
	const isMobile = useIsMobile();
	const padding = isMobile ? '2rem' : '2rem';

	return isMobile ? (
		<Box display="flex" flexDirection="column" width="100%">
			<Box
				display="flex"
				flexDirection="column"
				width="100%"
				bgcolor={theme.customColors.decentBlue}
			>
				<TitleSection
					title={title}
					shortDescription={shortDescription}
					author={author}
					onClick={onClick}
					buttonText={buttonText}
					renderContent={renderContent}
				/>
				<ImageSection imageUrl={imageUrl} accessibilities={accessibilities} />
			</Box>
			<InfoBoxSection infoBoxTitle={infoBoxTitle} infoBoxContent={infoBoxContent} />
			<DescriptionSection
				descriptionTitle={descriptionTitle}
				description={description}
				cooperations={cooperations}
				learningGoals={learningGoals}
				why={why}
				who={who}
				what={what}
				p={padding}
				structure={structure}
			/>
			{onClick ? (
				<FloatingActionButton2
					backgroundColor={theme.customColors.lightBlue}
					p="1rem"
					onClick={onClick}
					text={buttonText}
					iconName="PlayArrow"
					extended={true}
				/>
			) : null}
		</Box>
	) : (
		<GridContainer horizontalSpacing={horizontalSpacing} verticalSpacing={verticalSpacing}>
			{hideUpperSection ? null : isMobile ? null : (
				<>
					<GreyGrid
						{...gridItemLeftProps}
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<TitleSection
							title={title}
							shortDescription={shortDescription}
							author={author}
							onClick={onClick}
							buttonText={buttonText}
							renderContent={renderContent}
						/>
					</GreyGrid>
					<GreyGrid {...gridItemRightProps}>
						<ImageSection imageUrl={imageUrl} accessibilities={accessibilities} />
					</GreyGrid>
				</>
			)}

			<Grid {...gridItemLeftProps}>
				<DescriptionSection
					descriptionTitle={descriptionTitle}
					description={description}
					cooperations={cooperations}
					learningGoals={learningGoals}
					p={padding}
					why={why}
					who={who}
					what={what}
					techRequirements={techRequirements}
					requirements={requirements}
					structure={structure}
				/>
			</Grid>
			<Grid {...gridItemRightProps}>
				<InfoBoxSection infoBoxTitle={infoBoxTitle} infoBoxContent={infoBoxContent} />
			</Grid>
		</GridContainer>
	);
};

export default ContentHeader;
