import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Txt from './Txt';

export type RadioButtonRef = {
	reset: () => void;
	change: (value: string | null) => void;
};

const RadioButtonSection = React.forwardRef<
	RadioButtonRef,
	{
		id?: string;
		title?: string;
		options: { label: string | number; value: string | number }[];
		onChange?: ({
			identifier,
			value,
		}: {
			identifier?: string | null;
			value: string | number | null;
		}) => void;
		defaultValue?: string | number | null;
	}
>(({ id, title, options, onChange, defaultValue }, ref): JSX.Element => {
	const [selected, setSelected] = React.useState<null | string | undefined>(
		defaultValue ? String(defaultValue) : null
	);
	const RadioUI = React.useMemo(() => <Radio />, []);

	const handleChange = React.useCallback(
		(event) => {
			const newValue = event?.target?.value || event || null;
			const selectedIsNewValue = selected === newValue;
			setSelected(selectedIsNewValue ? null : newValue);
			onChange?.({ identifier: id, value: newValue });
		},
		[id, onChange, selected]
	);

	React.useImperativeHandle(
		ref,
		() => ({ reset: () => setSelected(null), change: (value) => handleChange(value) }),
		[handleChange]
	);

	return (
		<FormControl>
			{title ? (
				<Txt fontWeight="bold" m="1rem 0">
					{title}
				</Txt>
			) : null}
			<RadioGroup value={selected} onClick={handleChange}>
				{options.map(({ label, value }) => (
					<FormControlLabel key={value} value={value} control={RadioUI} label={label} />
				))}
			</RadioGroup>
		</FormControl>
	);
});

export default RadioButtonSection;
