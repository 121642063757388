import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Box, CircularProgress } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import ContentWrapper from '../components/ContentWrapper';
import Logo from '../components/Logo';
import RegisterForm from '../components/RegisterForm';
import { useProduct } from '../utils/product';

const Background = styled(Box)`
	display: flex;
	flex-direction: column;
	background-color: ${(p) => p.theme.customColors.decentBlue};
`;

const RegisterView = (): JSX.Element => {
	const [searchParams] = useSearchParams();
	const productId = searchParams.get('productId') || undefined;
	const contentId = searchParams.get('contentId') || undefined;
	const confirmationCode = searchParams.get('confirmationCode') || undefined;
	const email = searchParams.get('email') || undefined;
	const theme = useTheme();
	const product = useProduct(productId);
	return (
		<ContentWrapper center maxWidth={`${theme.authViewsMaxWidthPx}px`}>
			<Background m="4rem 0 4rem 0">
				<Logo width="8rem" m="2rem 0 0 2rem" />
				{confirmationCode ? (
					<RegisterForm defaultConfirmationCode={confirmationCode} defaultEmail={email} />
				) : productId && !product ? (
					<CircularProgress />
				) : (
					<RegisterForm contentId={contentId} />
				)}
			</Background>
		</ContentWrapper>
	);
};

export default RegisterView;
