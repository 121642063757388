import client from '../utils/client';
import { gql } from '@apollo/client';

type Error =
	| 'EMAIL_NOT_EXISTENT'
	| 'TOO_MANY_RESET_ATTEMPTS'
	| 'NETWORK_ERROR'
	| 'UNEXPECTED_ERROR'
	| 'CONFIRMATION_CODE_EXPIRED'
	| 'CONFIRMATION_CODE_INVALID';

const confirmPasswordReset = async ({
	email,
	password,
	confirmationCode,
}: {
	email: string;
	password: string;
	confirmationCode: string;
}): Promise<
	| { success: true; error: undefined; jwt: string }
	| { success: false; error: Error; jwt: undefined }
> => {
	try {
		const { data, errors } = await client.mutate({
			mutation: gql`
				mutation ConfirmPasswordReset(
					$confirmationCode: String!
					$email: String!
					$password: String!
				) {
					confirmPasswordReset(
						confirmationCode: $confirmationCode
						email: $email
						password: $password
					) {
						success
						jwt
					}
				}
			`,
			variables: { confirmationCode, email, password },
		});
		if (errors) {
			return { success: false, error: errors[0]?.extensions?.code as Error, jwt: undefined };
		}
		return {
			success: true,
			error: undefined,
			jwt: data.confirmPasswordReset.jwt,
		};
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR', jwt: undefined };
		throw e;
	}
};

export default confirmPasswordReset;
