import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column; 
	max-width: 800px; 
	margin: 0 auto;
	padding:15px;`;

const ImprintView = () => {
	return (
		<Wrapper>
			<ReactMarkdown>{
				`
## Impressum

### Angaben gemäß § 5 TMG

visionYOU GmbH Stahnsdorfer Straße 107 14482 Potsdam

Handelsregister: HRB 30652 P Registergericht: Potsdam


### Vertreten durch Geschäftsführerin:

Madeleine Wolf

### Kontakt

Telefon: +49 (0) 331 58256033 E-Mail: [info@visionyou.de](mailto:info@visionyou.de)

Für Support-Anfragen wende dich bitte an: [kontakt@bildungsfreaks.de](mailto:kontakt@bildungsfreaks.de)

### Umsatzsteuer-ID

Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE316732446

### Redaktionell verantwortlich

Paul Wolf
Stahnsdorfer Str. 107
14482 Potsdam

### EU-Streitschlichtung

Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr/.
Unsere E-Mail-Adresse finden Sie oben im Impressum.

Verbraucherstreitbeilegung / Universalschlichtungsstelle

Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.


`}
			</ReactMarkdown>
		</Wrapper>
	);
};

export default ImprintView;
