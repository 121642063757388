import client from '../utils/client';
import { gql } from '@apollo/client';

export type RequestPasswordResetError =
	| 'EMAIL_INVALID'
	| 'EMAIL_NOT_EXISTENT'
	| 'UNEXPECTED_ERROR'
	| 'NETWORK_ERROR'
	| 'CANNOT_CHANGE_PASSWORD_OF_TEST_USER';

const requestPasswordReset = async ({
	email,
}: {
	email: string;
}): Promise<
	{ success: true; error: undefined } | { success: false; error: RequestPasswordResetError }
> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation RequestPasswordReset($email: String!) {
					requestPasswordReset(email: $email) {
						success
					}
				}
			`,
			variables: {
				email,
			},
			errorPolicy: 'all',
		});
		if (errors) {
			return { success: false, error: errors[0]?.extensions?.code as RequestPasswordResetError };
		}
		return { success: true, error: undefined };
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default requestPasswordReset;
