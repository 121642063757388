import React from 'react';
import { useCurrentUser } from './user';

type AppState = 'LOGGED_IN' | 'LOGGED_OUT' | 'LOADING';

export const useAppState = () => {
	const currentUser = useCurrentUser();
	const appState: AppState = React.useMemo(
		() => (currentUser ? 'LOGGED_IN' : currentUser === null ? 'LOGGED_OUT' : 'LOADING'),
		[currentUser]
	);
	return appState;
};
