import * as React from 'react';
import styled from 'styled-components';
import { Accordion, AccordionDetails, AccordionSummary, TypographyProps } from '@mui/material';
import { Box } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import Txt from './Txt';
import { useIsMobile } from '../utils/hooks';

const StyledAccordionDetails = styled(AccordionDetails)`
	padding: 0;
	margin: 0;
`;

const StyledAccordion = styled(Accordion)((p) => ({}));

const StyledAccordionSummary = styled(AccordionSummary)<{
	expanded: 'true' | 'false';
	color?: string;
	$hasExpandedTitleLineBreak?: boolean;
	$hasTitleLineBreak?: boolean;
	$titleBackgroundColor?: string;
}>((p) => ({
	borderBottomColor:
		(p.$hasExpandedTitleLineBreak && p.expanded) || p.$hasTitleLineBreak
			? p.theme.palette.grey[200]
			: undefined,
	borderBottomStyle:
		(p.$hasExpandedTitleLineBreak && p.expanded) || p.$hasTitleLineBreak ? 'solid' : undefined,
	borderBottomWidth:
		(p.$hasExpandedTitleLineBreak && p.expanded === 'true') || p.$hasTitleLineBreak ? 1 : 0,
	backgroundColor: p.$titleBackgroundColor,
}));

const Expandable = ({
	id,
	label,
	rightLabel,
	labelVariant,
	children,
	m,
	defaultExpanded = false,
	expandedValue,
	onChange,
	renderLabel,
	disabled,
	maxWidth,
	onClick,
	hideElevation,
	hasExpandedTitleLineBreak = true,
	hasTitleLineBreak = false,
	hasBoldTitle,
	titleBackgroundColor,
}: {
	id?: string;
	label?: string;
	rightLabel?: string;
	labelVariant?: TypographyProps['variant'];
	children: JSX.Element | JSX.Element[];
	m?: string;
	defaultExpanded?: boolean;
	expandedValue?: boolean;
	onChange?: (changedId: string) => void;
	renderLabel?: (str?: string) => JSX.Element;
	disabled?: boolean;
	maxWidth?: string;
	onClick?: (changedId?: string) => void;
	hideElevation?: boolean;
	hasExpandedTitleLineBreak?: boolean;
	hasExpandedEndLineBreak?: boolean;
	hasTitleLineBreak?: boolean;
	hasBoldTitle?: boolean;
	titleBackgroundColor?: string;
}): JSX.Element => {
	const [expanded, setExpanded] = React.useState<boolean>(defaultExpanded);

	const handleToggleExpanded = React.useCallback(() => {
		if (expandedValue !== undefined) {
			//controlled component
			onChange?.(id!);
		} else {
			// uncontrolled component
			setExpanded((b) => !b);
		}
	}, [id, onChange, expandedValue]);

	const handleClick = React.useCallback(() => {
		onClick?.(id);
	}, [id, onClick]);

	const isMobile = useIsMobile();

	return (
		<Box m={m} maxWidth={maxWidth}>
			<StyledAccordion
				expanded={expandedValue !== undefined ? expandedValue : expanded}
				onChange={handleToggleExpanded}
				disabled={disabled}
				elevation={hideElevation ? 0 : undefined}
			>
				<Box onClick={handleClick}>
					<StyledAccordionSummary
						expanded={expanded.toString() as 'true' | 'false'}
						// eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
						expandIcon={<KeyboardArrowDown />}
						$hasExpandedTitleLineBreak={hasExpandedTitleLineBreak}
						$hasTitleLineBreak={hasTitleLineBreak}
						$titleBackgroundColor={titleBackgroundColor}
					>
						{renderLabel ? (
							renderLabel()
						) : (
							<Box
								display="flex"
								flexDirection={isMobile ? 'column' : 'row'}
								justifyContent="space-between"
								width="100%"
								m="0 1rem 0 0"
							>
								<Txt fontWeight={hasBoldTitle ? 'bold' : undefined} variant={labelVariant}>
									{label}
								</Txt>
								{rightLabel ? <Txt variant={labelVariant}>{rightLabel}</Txt> : null}
							</Box>
						)}
					</StyledAccordionSummary>
				</Box>

				<StyledAccordionDetails>{children}</StyledAccordionDetails>
			</StyledAccordion>
		</Box>
	);
};

export default React.memo(Expandable);
