import React from 'react';

import { ButtonProps } from '@mui/material';

import SelectButton from './SelectButton';

export type SortType = 'MOST_FAVOURITE' | 'NEWEST' | 'ASCENDING' | 'DESCENDING';

const options: { label: string; value: SortType }[] = [
	{ label: 'Beliebteste', value: 'MOST_FAVOURITE' },
	{ label: 'Neueste', value: 'NEWEST' },
	{ label: 'A-Z', value: 'ASCENDING' },
	{ label: 'Z-A', value: 'DESCENDING' },
];

const SortButton = ({
	onChange,
	variant,
	m,
}: {
	onChange: (value: SortType) => void;
	variant?: ButtonProps['variant'];
	m?: string;
}): JSX.Element => {
	return (
		<SelectButton
			label="Sortieren"
			options={options}
			onChange={onChange as (value?: string) => void}
			variant={variant}
			m={m}
		/>
	);
};

export default SortButton;
