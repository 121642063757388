import React from 'react';
import ImageBox from './ImageBox';
import image from '../images/BF_Logo_Banner.png';

const Logo = ({
	width = '100%',
	maxWidth = '10rem',
	height,
	navigateTo,
	m,
}: {
	width?: string;
	maxWidth?: string;
	height?: string;
	navigateTo?: string;
	m?: string;
}): JSX.Element => {
	return (
		<ImageBox
			navigateTo={navigateTo}
			src={image}
			width={width}
			maxWidth={maxWidth}
			height={height}
			m={m}
			objectFit="contain"
		/>
	);
};

export default Logo;
