import * as React from 'react';
import { Typography, Select, MenuItem } from '@mui/material';
import FormControlWrapper from './FormControlWrapper';

type ItemType = { label: string; value: string };

export type BooleanSelectFieldRef = {
	validate: () => boolean;
};

const BooleanSelectField = React.forwardRef<
	BooleanSelectFieldRef,
	{
		label: string;
		disabled?: boolean;
		onChange?: (value?: boolean) => void;
		defaultValue?: boolean;
		m?: string;
		variant?: 'outlined' | 'filled' | 'standard';
		flex?: boolean;
		width?: string;
	}
>(
	(
		{ label = 'No label provided', disabled, onChange, defaultValue, m, variant, flex, width },
		ref
	): JSX.Element => {
		const items: ItemType[] = React.useMemo(
			() => [
				{ label: 'Ja', value: 'true' },
				{ label: 'Nein', value: 'false' },
			],
			[]
		);
		const [errorMessage, setErrorMessage] = React.useState<undefined | string>();

		const [selectedValue, setSelectedValue] = React.useState<string>(
			defaultValue === true ? 'true' : 'false'
		);
		console.log({ defaultValue, selectedValue });

		const handleChange = React.useCallback(
			(event) => {
				setErrorMessage(undefined);
				const newValue = event?.target?.value;
				const sanitizedNewValue = typeof newValue === 'string' ? newValue : 'false';
				setSelectedValue(sanitizedNewValue);
				onChange?.(sanitizedNewValue === 'true' ? true : false);
			},
			[onChange]
		);

		const handleValidate = React.useCallback(() => {
			const invalid = !disabled && !selectedValue;
			if (invalid) {
				setErrorMessage('Bitte wählen');
			}
			return invalid;
		}, [disabled, selectedValue]);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		const renderValue = React.useCallback(
			(value: string) => {
				const newLabel = items.find((item) => item.value === value)?.label;
				return <Typography color="textPrimary">{newLabel}</Typography>;
			},
			[items]
		);

		React.useEffect(() => {
			if (disabled) {
				setErrorMessage(undefined);
			}
		}, [disabled]);

		return (
			<FormControlWrapper
				flex={flex}
				m={m}
				errorMessage={errorMessage}
				variant={variant}
				label={label}
				disabled={disabled}
				width={width}
			>
				<Select
					value={selectedValue}
					onChange={handleChange}
					renderValue={renderValue}
					disabled={disabled}
					variant={variant}
					label={label}
				>
					{items.map((item, index) => (
						<MenuItem key={index} value={item.value}>
							{item.label}
						</MenuItem>
					))}
				</Select>
			</FormControlWrapper>
		);
	}
);

export default React.memo(BooleanSelectField);
