import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import styled, { useTheme } from 'styled-components';

import ContentTile, {
	HEIGHT as CONTENT_TILE_HEIGHT,
	WIDTH as CONTENT_TILE_WIDTH,
} from './ContentTile';
import DemoContentTile, {
	HEIGHT as DEMO_TILE_HEIGHT,
	WIDTH as DEMO_TILE_WIDTH,
	MARGINS as DEMO_TILE_MARGINS,
} from './DemoContentTile';
import { type ContentInfos } from '../utils/content';
import { useDivDimensions, useScrolled, hexToRgba } from '../utils/hooks';

const COLUMN_GAP_REM = 1;

const Wrapper = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const RelativeWrapper = styled(Box)`
	width: 100%;
	position: relative;
`;

const Row = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	overflow-x: auto;
`;

const Overlay = styled(Box)<{ $height: number }>`
	position: absolute;
	width: 5rem;
	right: 0;
	top: 0;
	height: ${(p) => p.$height}rem;
	background: linear-gradient(
		to right,
		${(p) => hexToRgba(p.theme.customColors.lightGrey, 0)},
		${(p) => hexToRgba(p.theme.customColors.lightGrey, 1)}
	);
`;

const useShowOverlay = ({
	wrapperRef,
	rowRef,
	contents,
	contentTileWidthRem,
	contentTileMarginRightRem,
}: {
	wrapperRef: React.RefObject<HTMLDivElement>;
	rowRef: React.RefObject<HTMLDivElement>;
	contents: unknown[] | undefined;
	contentTileWidthRem: number;
	contentTileMarginRightRem: number;
}): boolean => {
	const theme = useTheme();
	const htmlFontSize = theme.typography.htmlFontSize;
	const { width: contentsRowWidthPixels } = useDivDimensions(wrapperRef, 'resize');
	const { scrolledFromRight } = useScrolled(rowRef);
	const showOverlay: boolean = React.useMemo(() => {
		if (!contents || scrolledFromRight <= 0 || contentsRowWidthPixels === undefined) return false;
		const contentTileTotalWidthRem = contentTileMarginRightRem + contentTileWidthRem;
		const allContentTilesTotalWidthRem = contents.length * contentTileTotalWidthRem;
		const allContentTilesTotalWidthPixels = allContentTilesTotalWidthRem * htmlFontSize;
		return allContentTilesTotalWidthPixels > contentsRowWidthPixels;
	}, [
		contentTileMarginRightRem,
		contentTileWidthRem,
		contents,
		contentsRowWidthPixels,
		htmlFontSize,
		scrolledFromRight,
	]);
	return showOverlay;
};

// TODO: should be replaced with SliderRow, which is the generalized version of this
const ContentsRow = ({
	contents,
	p,
	demo,
	showProgress,
}: {
	contents: ContentInfos[] | undefined;
	p?: string;
	demo?: boolean;
	showProgress?: boolean;
}): JSX.Element => {
	const wrapperRef = React.useRef<HTMLDivElement>(null);
	const rowRef = React.useRef<HTMLDivElement>(null);

	const showOverlay = useShowOverlay({
		wrapperRef,
		rowRef,
		contents,
		contentTileWidthRem: !demo ? CONTENT_TILE_WIDTH : DEMO_TILE_WIDTH,
		contentTileMarginRightRem: !demo ? COLUMN_GAP_REM : DEMO_TILE_MARGINS[1],
	});

	return (
		<Wrapper p={p} ref={wrapperRef}>
			<RelativeWrapper>
				<Row ref={rowRef} columnGap={`${COLUMN_GAP_REM}rem`}>
					{!contents ? (
						<CircularProgress />
					) : (
						contents.map((content) =>
							!demo ? (
								<ContentTile key={content.id} {...content} showProgress={showProgress} />
							) : (
								<DemoContentTile key={content.id} {...content} />
							)
						)
					)}
				</Row>
				{showOverlay ? <Overlay $height={!demo ? CONTENT_TILE_HEIGHT : DEMO_TILE_HEIGHT} /> : null}
			</RelativeWrapper>
		</Wrapper>
	);
};

export default React.memo(ContentsRow);
