import React from 'react';
import { Grid, Box } from '@mui/material';
import type { BoxProps as MuiBoxProps } from '@mui/material/Box';

interface GridItemProps extends MuiBoxProps {
	xs?: number;
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
}

const GridItem = ({ xs, sm, md, lg, xl, children, ...rest }: GridItemProps) => {
	return (
		<Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
			<Box
				display="flex"
				flexDirection="row"
				justifyContent="center"
				width="100%"
				height="100%"
				alignItems="center"
				{...rest}
			>
				{children}
			</Box>
		</Grid>
	);
};

export default React.memo(GridItem);
