import React from 'react';
import { Box } from '@mui/material';
import ContentWrapper from '../components/ContentWrapper';
import Txt from '../components/Txt';
import { useLicenses } from '../utils/licenses';

const LicensesView = () => {
	const licenses = useLicenses();

	return (
		<ContentWrapper center minHeight="10rem">
			{!licenses ? null : (
				<Box p="2rem">
					{licenses.map((text, index) => (
						<Txt m="2rem 0 0 0" key={index}>
							{text}
						</Txt>
					))}
					<Box m="0 0 2rem 0" />
				</Box>
			)}
		</ContentWrapper>
	);
};

export default LicensesView;
