/* eslint react/no-unescaped-entities: 0 */
import React from 'react';
import { useTheme } from 'styled-components';
import { Box } from '@mui/material';
import Button from './Button';
import Logo from './Logo';
import Txt from './Txt';
import MarkdownTxt from './MarkdownTxt';
import Switch from './Switch';
import { useCookies, CONSENT_COOKIE_VERSION } from '../utils/cookie';

import DialogWrapper from './DialogWrapper';
import { DialogButtonRow, DialogHeader } from './Dialog';

const cookieBannerTxt = `

###### Wir brauchen deine Zustimmung

Du sollst bildungsfreaks optimal nutzen können. Dafür setzen wir Cookies oder ähnliche
Technologien ein. Einige dieser Technologien sind für die Nutzung von bildungsfreaks
unbedingt notwendig. Wenn du uns deine Zustimmung gibst, können wir und unsere Dienstleister
auch Informationen auf deinem Gerät speichern oder abrufen. Wir werden deine Daten nicht
ohne deine Zustimmung an Dritte weitergeben, die nicht unsere direkten Dienstleister sind.

Es gibt bestimmte Datenverarbeitungen, für die wir deine Zustimmung benötigen:

**Externe Drittsysteme:** Wir nutzen Dienste von anderen Anbietern, um zum Beispiel Videoinhalte von YouTube in unsere Bildungsinhalte zu integrieren. Eine Wiedergabe von YouTube-Videos erfolgt nur, nachdem Du uns Deine Einwilligung zur Datenverarbeitung durch YouTube erteilt hast. Wenn Du einwilligst, werden Dir Inhalte von YouTube angezeigt und Deine IP-Adresse an externe Server übertragen. Bitte beachte, dass Daten im Rahmen der Nutzung von YouTube u.a. auch in die USA übermittelt werden können. Laut Urteil des Europäischen Gerichtshofs besteht derzeit kein angemessenes Schutzniveau für Datenübermittlungen in die USA. Dies kann mit verschiedenen Risiken für die Sicherheit der Datenverarbeitung einhergehen. Indem Sie auf "Zustimmen" klicken, willigst Du zugleich gem. Art. 49 Abs. 1 S. 1 lit. a) DSGVO ein, dass Deine Daten in den USA verarbeitet werden. Mehr Informationen zu den gesetzten Cookies findest Du in unserer Datenschutzerklärung.

Entscheide selbst, für welche Zwecke wir deine Daten speichern und verarbeiten dürfen. Das
betrifft nur das Gerät, das du aktuell verwendest. Durch Klicken auf &quot;Zustimmen&quot;
erklärst du dich mit unserer Datenverarbeitung einverstanden. Unter
&quot;Einstellungen/Ablehnen&quot; kannst du festlegen, für welche Zwecke du deine
Zustimmung gibst und für welche nicht. Du kannst deine Datenschutzeinstellungen jederzeit in
deinen Einstellungen widerrufen oder ändern, um Auswirkungen auf die zukünftige
Datenverarbeitung zu haben.

Hier geht’s zum [Impressum](/imprint_blank). Informationen zum Datenschutz findest du in
unserer [Datenschutzerklärung](/data-privacy_blank).
`;

const Wrapper = ({ children }: { children: JSX.Element | JSX.Element[] | null }): JSX.Element => {
	const theme = useTheme();

	return (
		<Box
			width="100%"
			height="2.5rem"
			borderRadius="0.5rem"
			display="flex"
			flexDirection="row"
			justifyContent="space-between"
			alignItems="center"
			m="0 0 1rem 0"
			bgcolor={theme.customColors.decentBlue}
			p="0 1rem"
		>
			{children}
		</Box>
	);
};

const CookieBannerDialog = ({ dismissPortal }: { dismissPortal: () => void }): JSX.Element => {
	const [_, setCookie] = useCookies();

	const [showDetails, setShowDetails] = React.useState(false);

	const handleAcceptCookiePolicy = React.useCallback(async () => {
		setCookie({
			cookieName: 'cookiePolicyResponse',
			cookieValue: {
				version: CONSENT_COOKIE_VERSION,
				allowedExternalServices: true,
			},
		});
		dismissPortal();
	}, [dismissPortal, setCookie]);

	const handleRejectCookiePolicy = React.useCallback(async () => {
		setCookie({
			cookieName: 'cookiePolicyResponse',
			cookieValue: {
				version: CONSENT_COOKIE_VERSION,
				allowedExternalServices: false,
			},
		});
		dismissPortal();
	}, [dismissPortal, setCookie]);

	const handleShowDetails = React.useCallback(async () => {
		setShowDetails(true);
	}, []);

	const [allowExternalCookies, setAllowExternalCookies] = React.useState(false);
	const handleChangeAllowExternalServices = React.useCallback(async (newValue) => {
		setAllowExternalCookies(newValue);
	}, []);

	const handleSaveResponse = React.useCallback(async () => {
		setCookie({
			cookieName: 'cookiePolicyResponse',
			cookieValue: {
				version: CONSENT_COOKIE_VERSION,
				allowedExternalServices: allowExternalCookies,
			},
		});
		dismissPortal();
	}, [allowExternalCookies, dismissPortal, setCookie]);

	return (
		<DialogWrapper>
			<Logo m="0 0 1rem 0" />
			<MarkdownTxt>{cookieBannerTxt}</MarkdownTxt>
			{showDetails ? (
				<>
					<DialogHeader title="Einstellungen anpassen" />
					<Wrapper>
						<Txt>Erforderlich</Txt>
						<Txt>Immer aktiv</Txt>
					</Wrapper>
					<Wrapper>
						<Txt>Externe Drittsysteme</Txt>
						<Switch
							defaultValue={allowExternalCookies}
							onChange={handleChangeAllowExternalServices}
							labelOff="Aus"
							labelOn="An"
						/>
					</Wrapper>
				</>
			) : null}
			<DialogButtonRow justifyContent="space-between">
				<Button onClick={handleAcceptCookiePolicy} variant="mainButton">
					Zustimmen
				</Button>
				{showDetails ? (
					<Box display="flex" flexDirection="row" justifyContent="flex-end">
						<Button m="0 1rem 0 0" onClick={handleRejectCookiePolicy} variant="secondButton">
							Alle ablehnen
						</Button>
						<Button onClick={handleSaveResponse} variant="mainButton">
							Auswahl speichern
						</Button>
					</Box>
				) : (
					<Button onClick={handleShowDetails} variant="secondButton">
						Einstellungen
					</Button>
				)}
			</DialogButtonRow>
		</DialogWrapper>
	);
};

export default CookieBannerDialog;
