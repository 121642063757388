/* eslint  no-useless-escape: 0 */

import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 800px;
	margin: 0 auto;
	padding: 15px;
`;

const paymentText = `
## Zahlung
Wir bieten folgende Zahlungsmöglichkeiten an 

### Paypal
Paypal ist der wohl bekannteste Zahlungsdienstleister in Deutschland. Es gelten die AGBs von Paypal, diese können unter folgender Adresse eingesehen werden: [https://www.paypal.com/de/webapps/mpp/ua/legalhub-full](https://www.paypal.com/de/webapps/mpp/ua/legalhub-full)

### Versandkosten	
Unsere Produkte sind online abrufbar und somit versandkostenfrei.
`
const PaymentTermsView = () => {

	return (
		<Wrapper>
			<ReactMarkdown>{paymentText}</ReactMarkdown>
		</Wrapper>
	);
};


export default PaymentTermsView;
