import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from 'styled-components';
import Txt from './Txt';
import { useGrouped, useNumberOfColumns } from '../utils/hooks';
import IconButton from './IconButton';

const LearningGoals = ({ items }: { items?: string[] }): JSX.Element | null => {
	const theme = useTheme();
	const ref = React.useRef<HTMLDivElement>(null);
	const { numberOfColumns } = useNumberOfColumns({ ref, minimumSize: 300 });
	const grouped = useGrouped({ items, nGroups: numberOfColumns });

	return (
		<Box width="100%" display="flex" flexDirection="row" ref={ref}>
			{grouped?.map((group, index) => (
				<Box
					m={index !== grouped.length - 1 ? '0 2rem 0 0' : '0'}
					display="flex"
					flexDirection="column"
					flex="1"
					key={index}
				>
					{group.map((item, groupIndex) => (
						<Box
							display="flex"
							m={groupIndex === group.length - 1 ? '0' : '0 0 1rem 0'}
							flexDirection="row"
							key={item as string}
						>
							<IconButton
								m="0 0.5rem 0 0"
								iconName="Check"
								iconColor={theme.customColors.lightBlue}
							/>
							<Txt key={item as string}>{item as string}</Txt>
						</Box>
					))}
				</Box>
			))}
		</Box>
	);
};

export default LearningGoals;
