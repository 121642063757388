import React from 'react';

import { useSnackbar } from '../utils/snackBar';
import Button from './Button';
import DialogWrapper from './DialogWrapper';
import OldTextField from './OldTextField';
import useTextFieldController from '../utils/useTextFieldController';
import { useCurrentUser } from '../utils/user';
import { validatePassword } from '../utils/helpers';
import updateInitialPassword from '../mutations/updateInitialPassword';
import { DialogButtonRow, DialogHeader } from './Dialog';

const UpdateInitialPasswordDialog = ({
	dismissPortal,
}: {
	dismissPortal: () => void;
}): JSX.Element => {
	const currentUser = useCurrentUser();
	const snackBar = useSnackbar();

	const [loading, setLoading] = React.useState(false);

	const {
		newStateValue: password,
		textInputProps: passwordFieldProps,
		validate: validatePasswordFn,
	} = useTextFieldController({
		defaultValue: '',
		inputLabel: 'Passwort',
		stateKey: 'password',
		validationFunction: validatePassword,
		stateValueFallback: '',
		textFieldType: 'password',
	});

	const handleUpdateInitialPassword = React.useCallback(async () => {
		const validationError = [validatePasswordFn!()].some(Boolean);
		if (!validationError) {
			setLoading(true);
			const { success, error } = await updateInitialPassword({
				email: currentUser!.email!,
				password: password!,
			});
			if (success) {
				snackBar({ success: 'Das Passwort wurde geändert!' });
				setLoading(false);
				dismissPortal();
			} else {
				setLoading(false);
				snackBar({ error });
			}
		}
	}, [currentUser, dismissPortal, password, snackBar, validatePasswordFn]);

	return (
		<DialogWrapper>
			<DialogHeader
				title="Passwort ändern"
				description="Um fortzufahren, muss das initiale Passwort geändert werden."
			/>
			<OldTextField m="1rem 0 0 0 " fullWidth {...passwordFieldProps} />
			<DialogButtonRow>
				<Button onClick={handleUpdateInitialPassword} loading={loading} variant="mainButton">
					Bestätigen
				</Button>
			</DialogButtonRow>
		</DialogWrapper>
	);
};

export default UpdateInitialPasswordDialog;
