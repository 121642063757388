import React from 'react';

import { ContentInfos } from './content';
import { Product, useAllProducts } from './product';
import { useAvailableCategories } from './category';
import { RadioButtonRef } from '../components/RadioButtonSection';
import { useCurrentUser } from './user';
import { Membership, useAllMemberships } from './membership';

const useFilterableProducts = (contents?: ContentInfos[]): Product[] | undefined => {
	const allProducts = useAllProducts();
	const productIds = React.useMemo(
		() =>
			contents?.reduce((acc: string[], nextContent: ContentInfos) => {
				const newAcc = [...acc];
				const contentProductIds = nextContent.accessibilities.find(
					({ type }) => type === 'PRODUCT'
				)?.ids;
				contentProductIds?.forEach((productId) => {
					if (!newAcc.some((pId) => pId === productId)) {
						newAcc.push(productId);
					}
				});
				return newAcc;
			}, []) || [],
		[contents]
	);

	const products = React.useMemo(
		() => allProducts?.filter(({ id }) => productIds.includes(id)),
		[allProducts, productIds]
	);
	return products;
};

const useFilterableMemberships = (contents?: ContentInfos[]): Membership[] | undefined => {
	const allMemberships = useAllMemberships();

	const filterableMemberships = React.useMemo(() => {
		const membershipIds = contents?.reduce<string[]>((acc, nextContent) => {
			const newAcc: string[] = [...acc];
			const contentMembershipIds = nextContent.accessibilities
				.filter(({ type }) => type === 'FREE_MEMBERSHIP' || type === 'PAID_MEMBERSHIP')
				.flatMap(({ ids }) => ids)
				.filter(Boolean);
			contentMembershipIds?.forEach((membershipId) => {
				if (!newAcc.some((mId) => mId === membershipId)) {
					newAcc.push(membershipId as string);
				}
			});
			return newAcc;
		}, []);
		const memberships = allMemberships?.filter(({ id }) => membershipIds?.includes(id));
		return memberships;
	}, [allMemberships, contents]);

	return filterableMemberships;
};

type FilterSection = {
	label: string;
	type:
		| 'AUDIENCE'
		| 'MEMBERSHIP'
		| 'PRODUCT'
		| 'COLLECTION'
		| 'EDUCATION_LEVEL'
		| 'EDUCATION_FORMAT';
	options: {
		label: string;
		value: string;
	}[];
	ref: React.RefObject<RadioButtonRef>;
};

const useFilterSections = (contents?: ContentInfos[]): FilterSection[] => {
	const currentUser = useCurrentUser();

	const filterableProducts = useFilterableProducts(contents);
	const filterableMemberships = useFilterableMemberships(contents);
	const filterableCollectionCategories = useAvailableCategories('COLLECTION', contents);
	const filterableEducationLevels = useAvailableCategories('EDUCATION_LEVEL', contents);
	const filterableAudiences = useAvailableCategories('AUDIENCE', contents);
	const filterableEducationFormats = useAvailableCategories('EDUCATION_FORMAT', contents);

	const collectionRef = React.useRef<RadioButtonRef>(null);
	const productRef = React.useRef<RadioButtonRef>(null);
	const membershipRef = React.useRef<RadioButtonRef>(null);
	const educationLevelRef = React.useRef<RadioButtonRef>(null);
	const audienceRef = React.useRef<RadioButtonRef>(null);

	const filterSections = React.useMemo(() => {
		return [
			currentUser
				? null
				: {
						label: 'Zielgruppe',
						type: 'AUDIENCE',
						options:
							filterableAudiences?.map(({ title, type }) => ({
								label: title,
								value: type,
							})) || [],
						ref: audienceRef,
				  },
			{
				label: 'Mitgliedschaft',
				type: 'MEMBERSHIP',
				options:
					filterableMemberships?.map(({ title, id }) => ({
						label: title,
						value: id,
					})) || [],
				ref: membershipRef,
			},
			currentUser?.audienceType === 'PUPILS' ||
			currentUser?.audienceType === 'COMPANY' ||
			!filterableProducts?.length
				? null
				: {
						label: 'Produkte',
						type: 'PRODUCT',
						options:
							filterableProducts?.map(({ title, id }) => ({ label: title, value: id })) || [],
						ref: productRef,
				  },
			{
				label: 'Thema',
				type: 'COLLECTION',
				options:
					filterableCollectionCategories?.map(({ title, type }) => ({
						label: title,
						value: type,
					})) || [],
				ref: collectionRef,
			},
			currentUser?.audienceType === 'COMPANY'
				? null
				: {
						label: 'Altersstufe',
						type: 'EDUCATION_LEVEL',
						options:
							filterableEducationLevels?.map(({ title, type }) => ({
								label: title,
								value: type,
							})) || [],
						ref: educationLevelRef,
				  },
			{
				label: 'Format',
				type: 'EDUCATION_FORMAT',
				options:
					filterableEducationFormats?.map(({ title, type }) => ({
						label: title,
						value: type,
					})) || [],
				ref: educationLevelRef,
			},
		].filter(Boolean) as FilterSection[];
	}, [
		currentUser,
		filterableAudiences,
		filterableMemberships,
		filterableProducts,
		filterableCollectionCategories,
		filterableEducationLevels,
		filterableEducationFormats,
	]);

	return filterSections;
};

export default useFilterSections;
