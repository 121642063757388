import React from 'react';
import { useNavigate } from '../utils/hooks';

import { useCurrentUser } from './user';
import { ContentDetails } from './content';
import Dialog from '../components/Dialog';
import createProductContentDownloadLink from '../mutations/createProductContentDownloadLink';
import { useSnackbar } from './snackBar';
import { triggerDownload } from './download';
import GetPaywallContactDialog from '../components/PaywallContactDialog';
import ConfirmTermsAndConditionsDialog from '../components/ConfirmTermsAndConditionsDialog';
import { useAppState } from '../utils/app';
import { Product } from '../utils/product';
import GetProContactDialog from '../components/GetProContactDialog';

export const useContentButtonProps = (
	content?: ContentDetails
): { label: string; onClick: () => void } | undefined => {
	const snackBar = useSnackbar();
	const navigate = useNavigate();
	const currentUser = useCurrentUser();

	// reasons for why a logged in user has access to a content
	const loggedInUserAccessibilities = React.useMemo(
		() => currentUser?.accessibleContentsInfo.find((c) => c.id === content?.id)?.accessibilities,
		[content?.id, currentUser?.accessibleContentsInfo]
	);

	// info about how a content can be accessed
	const contentAccessibilityTypes = content?.accessibilities?.map(({ type }) => type);

	// content is either accessible because it is register-free or the user is logged in and has acces to it
	const isAccessible =
		contentAccessibilityTypes?.includes('REGISTER_FREE') || loggedInUserAccessibilities?.length;

	const buttonLabel = React.useMemo(() => {
		if (content) {
			if (isAccessible) {
				return content.contentFormat === 'WEBSITE'
					? content.completion === 1
						? 'Kurs nochmal ansehen'
						: content.completion
						? 'Kurs fortsetzen'
						: 'Kurs starten'
					: 'Inhalt herunterladen';
			} else if (contentAccessibilityTypes?.includes('FREE_MEMBERSHIP')) {
				return 'Einloggen';
			} else if (contentAccessibilityTypes?.includes('PAID_MEMBERSHIP')) {
				return 'PRO werden';
			} else if (contentAccessibilityTypes?.includes('PRODUCT')) {
				return 'Kurs kaufen';
			} else {
				return '---';
			}
		}
	}, [content, contentAccessibilityTypes, isAccessible]);

	const handleStartOrDownload = React.useCallback(async () => {
		if (content) {
			if (isAccessible) {
				if (content.contentFormat === 'WEBSITE') {
					navigate(`/player/${content.id}?internal=true`);
				} else {
					const { success, error, presignedUrl } = await createProductContentDownloadLink(
						content.id
					);
					if (success) {
						triggerDownload(presignedUrl);
					} else {
						snackBar({ error });
					}
				}
			} else if (contentAccessibilityTypes?.includes('FREE_MEMBERSHIP')) {
				Dialog.render({
					title: 'Account erforderlich',
					description: 'Um Zugriff zu erhalten, musst du angemeldet sein.',
					buttons: [
						{
							label: 'Einloggen',
							id: 'Einloggen',
							onClick: () => navigate(`/login?contentId=${content.id}`),
						},
						{
							label: 'Registrieren',
							id: 'Registrieren',
							onClick: () => navigate(`/signup?contentId=${content.id}`),
						},
					],
				});
			} else if (contentAccessibilityTypes?.includes('PRODUCT')) {
				Dialog.render({
					renderContent: ({ dismissPortal }) => (
						<GetPaywallContactDialog dismissPortal={dismissPortal} id={content!.id} />
					),
				});
			} else if (contentAccessibilityTypes?.includes('PAID_MEMBERSHIP')) {
				const paidMembershipIds = content.accessibilities?.find(
					({ type }) => type === 'PAID_MEMBERSHIP'
				)?.ids;
				navigate(`/pricing?membershipIds=${encodeURIComponent(paidMembershipIds!.join(','))}`);
			} else {
				throw new Error('This should not happen');
			}
		}
	}, [content, contentAccessibilityTypes, isAccessible, navigate, snackBar]);

	const maybeHandleConfirmTerms = React.useCallback(() => {
		if (contentAccessibilityTypes?.includes('REGISTER_FREE') && !currentUser) {
			Dialog.render({
				renderContent: ConfirmTermsAndConditionsDialog,
				onSuccess: handleStartOrDownload,
			});
		} else {
			handleStartOrDownload();
		}
	}, [contentAccessibilityTypes, currentUser, handleStartOrDownload]);

	return !content ? undefined : { label: buttonLabel!, onClick: maybeHandleConfirmTerms };
};

export const useProductButtonProps = (
	product?: Product
): { label?: string; onClick?: () => void } | undefined => {
	const handleShowGetProContactDialog = React.useCallback(() => {
		Dialog.render({
			renderContent: ({ dismissPortal }) => <GetProContactDialog dismissPortal={dismissPortal} />,
		});
	}, []);

	const user = useCurrentUser();
	const userHasProduct = user?.products.some((prod) => prod.id === product?.id);
	const appState = useAppState();
	const productAction: 'REGISTER_AND_BUY' | 'START' | 'BUY' =
		appState === 'LOGGED_OUT' ? 'REGISTER_AND_BUY' : userHasProduct ? 'START' : 'BUY';

	const buttonLabel =
		productAction === 'REGISTER_AND_BUY' || productAction === 'BUY'
			? 'Jetzt PRO werden'
			: undefined;

	const clickHandler = React.useCallback(
		() =>
			productAction === 'START'
				? undefined
				: productAction === 'REGISTER_AND_BUY' || productAction === 'BUY'
				? handleShowGetProContactDialog()
				: undefined,
		[handleShowGetProContactDialog, productAction]
	);

	return { label: buttonLabel, onClick: clickHandler };
};
