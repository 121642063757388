import React from 'react';
import { useQuery, gql } from '@apollo/client';

export const useLicenses = (): string[] | undefined => {
	const { data } = useQuery(
		gql`
			query {
				licenses
			}
		`
	);

	return React.useMemo(() => data?.licenses, [data?.licenses]);
};
