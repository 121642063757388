import React from 'react';
import { Box } from '@mui/material';
import proBadgeIcon from '../images/Pro_Badge_Boxed.png';
import freeBadgeIcon from '../images/Free-Badge.svg';

import ImageBox from './ImageBox';
import { ContentDetails } from '../utils/content';

const Badge = ({
	width = '4rem',
	height,
	accessibilities,
}: {
	width?: string;
	height?: string;
	accessibilities?: ContentDetails['accessibilities'];
}): JSX.Element | null => {
	const accessibilityTypes = React.useMemo(
		() => accessibilities?.map(({ type }) => type),
		[accessibilities]
	);

	return !accessibilities ? null : (
		<Box display="flex" flexDirection="row" alignItems="center">
			<ImageBox
				width={width}
				height={height}
				src={
					accessibilityTypes?.includes('FREE_MEMBERSHIP') ||
					accessibilityTypes?.includes('REGISTER_FREE')
						? freeBadgeIcon
						: accessibilityTypes?.includes('PAID_MEMBERSHIP')
						? proBadgeIcon
						: undefined
				}
			/>
		</Box>
	);
};

export default Badge;
