import React from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';

import Txt from './Txt';
import Button from './Button';
import MarkdownTxt from './MarkdownTxt';
import ImageBox from './ImageBox';

import image from '../images/BF_GetPro_Lehrer.png';
import proImage from '../images/Pro_Badge.png';
import { DialogButtonRow } from './Dialog';
import { useActiveBreakPoint } from '../utils/theme';
import { CONTACT_EMAIL } from '../consts';
import { useDialogDimensions } from '../utils/hooks';

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	flex: 1;
`;
const Column = styled(Box)`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 2rem;
`;

const StyledImage = styled.img``;

const GetProContactDialog = ({ dismissPortal }: { dismissPortal?: () => void }): JSX.Element => {
	const activeBreakPoint = useActiveBreakPoint();
	const collapsed = ['xs', 'sm'].includes(activeBreakPoint);
	const { width } = useDialogDimensions();

	return (
		<Box flexDirection="column" alignItems="center" width={width} position="relative">
			<Row position="relative">
				<Column maxWidth={collapsed ? '100%' : '80%'} zIndex={collapsed ? undefined : 100}>
					<ImageBox src={proImage} width="50%" />
					<Txt variant="h6" m="0 0 1rem 0">
						Du willst PRO werden?
					</Txt>
					<MarkdownTxt m="0 0 1rem 0">
						{`Schreibe uns eine E-Mail mit dem Betreff  "PRO" an [${CONTACT_EMAIL}](mailto:${CONTACT_EMAIL})`}
					</MarkdownTxt>
					{collapsed ? null : (
						<DialogButtonRow justifyContent="flex-start">
							<Button onClick={dismissPortal}>Schließen</Button>
						</DialogButtonRow>
					)}
				</Column>
				{collapsed ? null : (
					<Box
						display="flex"
						flexDirection="row"
						justifyContent="flex-end"
						position="absolute"
						top="0"
						right="0"
						bottom="0"
						left="0"
						zIndex={10}
					>
						<StyledImage src={image} />
					</Box>
				)}
			</Row>
		</Box>
	);
};

export default GetProContactDialog;
