import React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import MuiSwitch from '@mui/material/Switch';
import { SwitchProps, FormControl, FormHelperText } from '@mui/material';

import MarkdownTxt from './MarkdownTxt';
import Txt from './Txt';

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export type SwitchRef = {
	validate: () => boolean;
};

const Switch = React.forwardRef(
	(
		{
			labelOff,
			labelOn,
			onChange,
			m,
			p,
			size,
			defaultValue,
			customErrorMessage,
			isMarkdown,
		}: {
			labelOff: string;
			labelOn: string;
			onChange?: (value: boolean) => void;
			m?: string;
			p?: string;
			size?: SwitchProps['size'];
			defaultValue?: boolean;
			customErrorMessage?: string;
			isMarkdown?: boolean;
		},
		ref
	) => {
		const [checked, setChecked] = React.useState(Boolean(defaultValue));
		const [errorMessage, setErrorMessage] = React.useState<undefined | string>();
		const handleToggleChecked = React.useCallback(() => {
			setErrorMessage(undefined);
			const newValue = !checked;
			onChange?.(newValue);
			setChecked(newValue);
		}, [checked, onChange]);

		const handleValidate = React.useCallback(() => {
			const invalid = !checked;
			if (invalid) {
				setErrorMessage(customErrorMessage || 'Bitte zustimmen');
				return true;
			}
			return false;
		}, [checked, customErrorMessage]);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		return (
			<Row m={m} p={p}>
				<FormControl error={Boolean(errorMessage)}>
					<Box display="flex" flexDirection="row" width="100%" alignItems="center">
						{isMarkdown ? (
							<MarkdownTxt>{checked ? labelOn : labelOff}</MarkdownTxt>
						) : (
							<Txt>{checked ? labelOn : labelOff}</Txt>
						)}
						<MuiSwitch size={size} checked={checked} onChange={handleToggleChecked} />
					</Box>
					{errorMessage ? <FormHelperText>{errorMessage}</FormHelperText> : null}
				</FormControl>
			</Row>
		);
	}
);

export default React.memo(Switch);
