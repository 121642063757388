import React from 'react';

import { useCurrentUser } from '../utils/user';
import LoggedInAccessPhraseUserView from './LoggedInAccessPhraseUserView';
import LoggedInUserView from './LoggedInUserView';

const LoggedInView = (): JSX.Element | null => {
	const currentUser = useCurrentUser();

	return !currentUser ? null : currentUser.isAccessPhraseUser ? (
		<LoggedInAccessPhraseUserView />
	) : (
		<LoggedInUserView />
	);
};

export default LoggedInView;
