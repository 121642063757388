import React from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';
import Button from './Button';

const Wrapper = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const Row = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const FilterRow = ({
	m,
	p,
	onChange,
	options,
	defaultValue,
}: {
	m?: string;
	p?: string;
	onChange: (option: string) => void;
	options: { label: string; value: string }[];
	defaultValue?: string;
}): JSX.Element => {
	const [selected, setSelected] = React.useState<undefined | string>(undefined);

	const handleClick = React.useCallback(
		(option: string) => {
			setSelected(option);
			onChange?.(option);
		},
		[onChange]
	);

	React.useEffect(() => {
		const defaultItem = defaultValue
			? options.find(({ value }) => value === defaultValue)
			: options[0];
		if (!defaultItem) return;
		handleClick(defaultItem!.value);
	}, [defaultValue, handleClick, options]);

	return (
		<Wrapper m={m} p={p} overflow="scroll">
			<Row>
				{options.map(({ label, value }) => (
					<Button
						key={value}
						variant={selected === value ? 'mainButton' : undefined}
						m="0 1.5rem 0 0"
						id={value}
						onClick={handleClick}
					>
						{label}
					</Button>
				))}
			</Row>
		</Wrapper>
	);
};

export default React.memo(FilterRow);
