import React from 'react';
import { CircularProgress } from '@mui/material';
import FilterRow from './FilterRow';

import { CategoryGroupName, useCategoriesOfGroup, Category } from '../utils/category';

const CategoryTypeSelect = ({
	m,
	p,
	onChange,
	categoryGroupName,
	categories,
}: {
	m?: string;
	p?: string;
	onChange: (value: string) => void;
	categoryGroupName?: CategoryGroupName;
	categories?: Category[];
}): JSX.Element | null => {
	const allCategoriesOfGroup = useCategoriesOfGroup(categoryGroupName);
	const categoryOptions = React.useMemo(
		() =>
			(categories || allCategoriesOfGroup)?.map(({ title, type }) => ({
				label: title,
				value: type,
			})),
		[allCategoriesOfGroup, categories]
	);

	const handleChange = React.useCallback(
		(categoryType: string) => {
			onChange?.(categoryType);
		},
		[onChange]
	);

	return !categoryOptions ? (
		<CircularProgress />
	) : categoryOptions.length === 1 ? null : (
		<FilterRow m={m} p={p} options={categoryOptions} onChange={handleChange} />
	);
};

export default React.memo(CategoryTypeSelect);
