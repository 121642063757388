import * as React from 'react';
import {
	FormControl,
	Typography,
	Select,
	MenuItem,
	InputLabel,
	Box,
	FormHelperText,
} from '@mui/material';

import styled from 'styled-components';
import type { SelectChangeEvent } from '@mui/material';

type ItemType = { label: string; value: string };

const decodePropsValue = (propsValue?: string | null): string | '' => propsValue || '';
const encodeStateValue = (stateValue: string | ''): string | null => stateValue || null;

export type SelectFieldRef = {
	validate: () => boolean;
};

const StyledSelect = styled(Select)<{ $backgroundColor?: string }>`
	background-color: ${(p) => p.$backgroundColor || 'none'};
`;

const SelectField = React.forwardRef<
	SelectFieldRef,
	{
		fullWidth?: boolean;
		label: string;
		disabled?: boolean;
		onChange?: (output: string | null) => void;
		defaultValue?: string | null;
		margin?: string;
		variant?: 'outlined' | 'filled' | 'standard';
		flex?: boolean;
		nothingSelectedLabel?: string;
		items?: ItemType[];
		required?: boolean;
		requiredText?: string;
		backgroundColor?: string;
	}
>(
	(
		{
			fullWidth,
			label = 'No label provided',
			disabled,
			onChange,
			defaultValue,
			margin,
			variant,
			flex,
			nothingSelectedLabel = '-',
			items,
			required = false,
			requiredText = 'Bitte wählen',
			backgroundColor,
		},
		ref
	): JSX.Element => {
		const combinedItems: ItemType[] = React.useMemo(
			() => [{ label: nothingSelectedLabel, value: '' }, ...(items?.length ? items : [])],
			[items, nothingSelectedLabel]
		);

		const [selectedValue, setSelectedValue] = React.useState<string | ''>(
			decodePropsValue(defaultValue)
		);

		const [errorMessage, setErrorMessage] = React.useState<null | string>(null);

		const handleChange = React.useCallback(
			(event: SelectChangeEvent<string> | { target: { value: string } }) => {
				const newValue = event?.target?.value as string | '';
				setErrorMessage(null);
				setSelectedValue(newValue);
				onChange && onChange(encodeStateValue(newValue));
			},
			[onChange]
		);

		const renderValue = React.useCallback(
			(value: string | '') => {
				const newLabel = items?.find((item) => item.value === value)?.label;
				return <Typography color="textPrimary">{newLabel}</Typography>;
			},
			[items]
		);

		const handleValidate = React.useCallback(() => {
			const newErrorMessage = !selectedValue ? requiredText : null;
			setErrorMessage(newErrorMessage);
			return Boolean(newErrorMessage);
		}, [requiredText, selectedValue]);

		React.useImperativeHandle(ref, () => ({
			validate: () => handleValidate(),
		}));

		const handleBlur = React.useCallback(() => {
			if (required) {
				handleValidate();
			}
		}, [handleValidate, required]);

		return (
			<Box m={margin} flex={flex ? '1' : undefined}>
				<FormControl fullWidth={fullWidth} variant={variant} error={Boolean(errorMessage)}>
					<InputLabel variant={variant} htmlFor="uncontrolled-native">
						{label}
					</InputLabel>
					<StyledSelect
						value={selectedValue}
						onChange={handleChange}
						renderValue={renderValue}
						disabled={disabled}
						variant={variant}
						label={label}
						onBlur={handleBlur}
						$backgroundColor={backgroundColor}
					>
						{combinedItems.map((item) => (
							<MenuItem key={item.value} value={item.value}>
								{item.label}
							</MenuItem>
						))}
					</StyledSelect>
					<FormHelperText>{errorMessage}</FormHelperText>
				</FormControl>
			</Box>
		);
	}
);

export default React.memo(SelectField);
