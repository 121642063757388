import React from 'react';
import { useNavigate } from '../utils/hooks';
import { useTheme } from 'styled-components';

import { Box, BoxProps } from '@mui/material';
import { useSnackbar } from '../utils/snackBar';
import { useCurrentUser } from '../utils/user';
import { useAppState } from '../utils/app';
import toggleFavouriteContent from '../mutations/toggleFavouriteContent';
import Dialog from './Dialog';
import IconButton from './IconButton';

const FavouriteIcon = ({ contentId, ...rest }: { contentId: string } & BoxProps): JSX.Element => {
	const snackBar = useSnackbar();
	const currentUser = useCurrentUser();
	const isFavourite = currentUser?.favouriteContents.some((c) => c.id === contentId);
	const theme = useTheme();
	const appState = useAppState();
	const navigate = useNavigate();

	const handleAddFavourites = React.useCallback(async () => {
		if (appState === 'LOGGED_OUT') {
			Dialog.render({
				title: 'Account erforderlich',
				description:
					'Um Favoriten speichern zu können, musst du dich zunächst registrieren. Du hast schon einen Account? Dann logge dich einfach ein.',
				buttons: [
					{
						label: 'Registrieren',
						id: 'Registrieren',
						onClick: () => navigate('/signup'),
					},
					{
						label: 'Einloggen',
						id: 'Einloggen',
						onClick: () => navigate('/login'),
						variant: 'mainButton',
					},
				],
			});
		} else {
			const { success, error } = await toggleFavouriteContent(contentId);
			if (!success) {
				snackBar({ error });
			}
		}
	}, [appState, contentId, navigate, snackBar]);

	return (
		<Box display="flex" {...rest}>
			<IconButton
				iconColor={isFavourite ? theme.customColors.orange : ''}
				iconName={isFavourite ? 'Favorite' : 'FavoriteBorder'}
				onClick={handleAddFavourites}
			/>
		</Box>
	);
};

export default FavouriteIcon;
