import React from 'react';
import { useTheme } from 'styled-components';
import { TextField as MuiTextField, Box } from '@mui/material';
import { FilledTextFieldProps as MuiFilledTextFieldProps } from '@mui/material/TextField';

interface FilledTextFieldProps extends MuiFilledTextFieldProps {
	m?: string;
	p?: string;
	backgroundColor?: string;
}

const FilledTextField = ({ m, p, backgroundColor, ...rest }: FilledTextFieldProps) => {
	const theme = useTheme();

	const inputProps = React.useMemo(
		() => ({
			style: {
				backgroundColor: backgroundColor || theme.customColors.white,
			},
		}),
		[backgroundColor, theme.customColors.white]
	);

	return (
		<Box m={m} p={p}>
			<MuiTextField {...rest} InputProps={inputProps} />
		</Box>
	);
};

export default FilledTextField;
