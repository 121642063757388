import React from 'react';

import { useSnackbar } from '../utils/snackBar';
import CheckBox2, { CheckBoxRef2 } from './CheckBox2';
import DialogWrapper from './DialogWrapper';
import { DialogHeader, DialogButtonRow } from './Dialog';
import Button from './Button';
import acceptTermsVersion from '../mutations/acceptTermsVersion';
import Logo from './Logo';
import Txt from './Txt';
import { termsWarningTemporaryHiddenVar } from '../utils/localState';

const termsLabel = 'Nutzungsbedingungen';

const ConfirmTermsAndConditionsDialog = ({
	dismissPortal,
	onSuccess,
	version,
}: {
	dismissPortal: () => void;
	onSuccess?: () => void;
	version?: number;
}): JSX.Element => {
	const snackBar = useSnackbar();
	const [loading, setLoading] = React.useState(false);

	const termsRef = React.useRef<CheckBoxRef2>(null);

	const handleSubmit = React.useCallback(async () => {
		const invalid = [termsRef].map((r) => r.current?.validate()).some(Boolean);
		if (invalid) {
			return;
		}
		setLoading(true);
		const { success, error } = await acceptTermsVersion();
		if (success) {
			setLoading(false);
			snackBar({ success: `${termsLabel}  akzeptiert.` });
			onSuccess?.();
			dismissPortal();
		} else {
			setLoading(false);
			snackBar({ error });
		}
	}, [dismissPortal, onSuccess, snackBar]);
	const handleLater = React.useCallback(async () => {
		termsWarningTemporaryHiddenVar(true);
		dismissPortal();
	}, [dismissPortal]);

	if (!version) {
		// User has not confirmed any terms yet
		return (
			<DialogWrapper>
				<DialogHeader
					title="Bevor es losgeht"
					description={`Um fortzufahren, musst du den ${termsLabel} von
				bildungsfreaks zustimmen.`}
				/>
				<CheckBox2
					size="small"
					label={`Hiermit bestätige ich, dass ich den [${termsLabel}](/terms_blank) von bildungsfreaks zustimme.`}
					ref={termsRef}
					isMarkdown
				/>
				<DialogButtonRow>
					<Button variant="mainButton" onClick={handleSubmit} loading={loading}>
						Bestätigen
					</Button>
				</DialogButtonRow>
			</DialogWrapper>
		);
	} else {
		// User has not confirmed latest terms but older ones
		return (
			<DialogWrapper>
				<Logo />
				<Txt variant="h4" gutterBottom fontWeight="bold" pt="2rem">
					Änderungen der Nutzungsbedingungen
				</Txt>
				<Txt variant="h6">zum 26. August 2023</Txt>

				<Txt variant="body1" pt="1.5rem" pb="1.5rem">
					bildungsgfreaks wird stetig weiterentwickelt. Neben verbesserten Funktionen, richtet sich
					das Angebot mittlerweile an verschiedene Zielgruppen, darunter auch Verbraucher*innen. Um
					rechtlichen Anforderungen genüge zu tragen und für höhere Transparenz zu sorgen, haben wir
					unsere Nutzungsbedingungen überarbeitet und modular aufgebaut. Bitte lies und bestätige
					die neuen Nutzungsbedingungen bis zum 25. August 2023.
				</Txt>
				<CheckBox2
					size="small"
					label={`Hiermit bestätige ich, dass ich den [${termsLabel}](/terms_blank) von bildungsfreaks zustimme.`}
					ref={termsRef}
					isMarkdown
				/>
				<DialogButtonRow>
					<Button
						variant="secondButton"
						onClick={handleLater}
						loading={loading}
						color="secondary"
						m="1rem"
					>
						Später akzeptieren
					</Button>
					<Button variant="mainButton" onClick={handleSubmit} loading={loading}>
						Speichern
					</Button>
				</DialogButtonRow>
			</DialogWrapper>
		);
	}
};

export default ConfirmTermsAndConditionsDialog;
