import React from 'react';
import ContentWrapper from '../components/ContentWrapper';
import Txt from '../components/Txt';
import { useCurrentUser } from '../utils/user';

const AdminView = (): JSX.Element | null => {
	const currentUser = useCurrentUser();

	return currentUser === undefined ? (
		<ContentWrapper>{null}</ContentWrapper>
	) : (
		<ContentWrapper>
			<Txt>Home</Txt>
		</ContentWrapper>
	);
};

export default AdminView;
