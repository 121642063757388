import client from '../utils/client';
import { gql } from '@apollo/client';

type Error = 'NETWORK_ERROR' | 'NO_CERTIFICATE_FOUND' | 'UNAUTHENTICATED';

const createCertificateDownloadLink = async (
	certificateId: string
): Promise<
	| { success: true; error: undefined; presignedUrl: string }
	| { success: false; error: Error; presignedUrl: undefined }
> => {
	try {
		const { data, errors } = await client.mutate({
			mutation: gql`
				mutation CreateCertificateDownloadLink($certificateId: ID!) {
					createCertificateDownloadLink(certificateId: $certificateId) {
						success
						presignedUrl
					}
				}
			`,
			variables: { certificateId },
		});
		if (errors) {
			return {
				success: false,
				error: errors[0]?.extensions?.code as Error,
				presignedUrl: undefined,
			};
		}
		return {
			success: true,
			error: undefined,
			presignedUrl: data.createCertificateDownloadLink.presignedUrl,
		};
	} catch (e) {
		if (e && e.networkError)
			return { success: false, error: 'NETWORK_ERROR', presignedUrl: undefined };
		throw e;
	}
};

export default createCertificateDownloadLink;
