import client from '../utils/client';
import { gql } from '@apollo/client';

type Error =
	| 'NETWORK_ERROR'
	| 'CONFIRMATION_CODE_INVALID'
	| 'EMAIL_ALREADY_CONFIRMED'
	| 'UNEXPECTED_ERROR'
	| 'CONFIRMATION_CODE_EXPIRED';

const updateInitialPassword = async ({
	email,
	password,
}: {
	email: string;
	password: string;
}): Promise<{ success: true; error: undefined } | { success: false; error: Error }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation UpdateInitialPassword($password: String!, $email: String!) {
					updateInitialPassword(password: $password, email: $email) {
						id
						hasChangedInitialPassword
					}
				}
			`,
			variables: { password, email },
		});
		if (errors) {
			return { success: false, error: errors[0]?.extensions?.code as Error };
		}
		return {
			success: true,
			error: undefined,
		};
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default updateInitialPassword;
