import React from 'react';
import { Box } from '@mui/material';

import Txt from './Txt';
import DialogWrapper from './DialogWrapper';
import Button from './Button';
import MarkdownTxt from './MarkdownTxt';
import { useContentInfo } from '../utils/content';
import { CONTACT_EMAIL } from '../consts';

const PaywallContactDialog = ({
	dismissPortal,
	id,
}: {
	dismissPortal: () => void;
	id?: string;
}): JSX.Element => {
	const content = useContentInfo(id);
	return (
		<DialogWrapper>
			{!content ? null : (
				<>
					<Txt variant="h6" m="0 0 1rem 0">
						Du willst diesen Produktinhalt kaufen?
					</Txt>
					<MarkdownTxt m="0 0 1rem 0">
						{`Schreibe uns eine email mit dem Betreff  "${content.title}" an [${CONTACT_EMAIL}](mailto:${CONTACT_EMAIL})`}
					</MarkdownTxt>
					<Box
						m="2rem 0 0 0"
						width="100%"
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="flex-end"
					>
						<Button variant="mainButton" onClick={dismissPortal}>
							Ok
						</Button>
					</Box>
				</>
			)}
		</DialogWrapper>
	);
};

export default PaywallContactDialog;
