import React from 'react';
import { useLocation } from 'react-router-dom';
import CookiesDisabled from '../components/CookiesDisabled';
import ReactPlayerWrapper from '../components/ReactPlayerWrapper';
import { useExternalCookiesAllowed } from '../utils/cookie';


const YoutubeIframeView = () => {

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const width = queryParams.get('width') || "100%";
	const height = queryParams.get('height') || "100%";
	const ccLoadPolicy = queryParams.get('cc_load_policy') || "1";
	const hl = queryParams.get('hl') || "de";
	const start = Number(queryParams.get('start') || "0");
	const end = queryParams.get('end');
	const videoId = queryParams.get('video_id') || queryParams.get('videoId') || "";

	const externalCookiesAllowed = useExternalCookiesAllowed();
	const src = `https://www.youtube-nocookie.com/embed/${videoId}?rel=0&modestbranding=1&hl=${hl}&cc_load_policy=${ccLoadPolicy}${start ? `&start=${start}` : ''}${end ? `&end=${end}` : ''}`
	return externalCookiesAllowed ? (
		<iframe style={{ border: 0, width, height, position: "absolute" }}
			src={src} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen ></iframe>
	) : (
		<CookiesDisabled width="90%" height="100%" style={{
			paddingTop: 10
		}} />

	);
};

export default YoutubeIframeView;
