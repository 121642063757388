import { LATEST_TERMS_VERSION } from '../consts';
import client from '../utils/client';
import { gql } from '@apollo/client';

type Error = 'NETWORK_ERROR' | 'UNAUTHENTICATED';

const acceptTermsVersion = async (): Promise<
	{ success: true; error: undefined } | { success: false; error: Error }
> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation AcceptTermsVersion($version: Int!) {
					acceptTermsVersion(version:$version) {
						id
						termsAcceptedVersion
					}
				}
			`, variables: { version: LATEST_TERMS_VERSION },
		});
		if (errors) {
			return {
				success: false,
				error: errors[0]?.extensions?.code as Error,
			};
		}
		return {
			success: true,
			error: undefined,
		};
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default acceptTermsVersion;
